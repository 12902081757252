/* Footer Section */
#footer {
    background-color: var(--accent-color);
    padding-top: 7.5rem
}

#footer,
#footer a svg {
    color: #fff;
    text-align: center
}

#footer a svg {
    height: 1.5rem;
    width: 2.25rem;
    padding: .25rem 0;
    margin: 5px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: .25rem;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

#footer a svg:hover {
    color: var(--accent-color);
    background-color: #fff;
    border-color: #fff;
}
