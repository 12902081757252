/* Fonts */
h1,h2,h3 {
    margin-bottom: 1rem;
}

/* Section Body changes */
.home-section .section-body {
    padding: 2.5rem;
    margin: 0 auto;
    max-width: 62.5rem;
}

/* Intro Section */
#intro {
    padding-top: 2.5rem;
    padding-bottom: 10rem;
    color: #fff;
    text-align: center;
    min-height: 95vh;
    background-color: var(--accent-color);
}

#intro p {
    font-size: 1.5rem;
}

#intro p.short {
    max-width: 50rem;
    margin: 0 auto;
}

#intro h1 {
    font-size: 2.25rem!important
}

#intro a {
    width: 50%;
    margin: 0 auto
}

.down-arrow {
    text-align: center;
    margin: 3rem 0;
}

.down-arrow:hover {
    cursor: pointer;
}

.bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

.no-display {
    display:none;
}
  
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
}

@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
}

@media only screen and (max-width:750px) {
    .down-arrow {
        display: none;
    }
}

/* Skills Section */
#skills {
    margin-top: -10rem;
    text-align: center
}

#skills .box section {
    margin: 2rem 0
}

.skills-heading {
    margin: .5rem!important;
    color: var(--accent-color);
}

#skills p {
    margin: 0;
    padding: 0
}

#skills ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}
