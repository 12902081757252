.box article {
    flex-grow: 1;
    flex-basis: 0;
    -ms-flex-positive: 1;
    -ms-flex-preferred-size: 0;
    color: var(--text-color);
    display: block;
    padding: 2.5rem;
    border-right: 1px solid var(--border-color);
    border-bottom: 0 solid var(--border-color);
}

@media only screen and (max-width:750px) {
    .box article {
        border-right: 0 solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
    }
}