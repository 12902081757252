/* Resume Section */
#resume {
    font-size: 1rem
}

#resume h1 {
    font-size: 2em;
}

/* Section body changes */
.resume-section .section-body {
    padding: 0;
    margin: 0; 
    width: 100%;
    height: 100%;

    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
}

.resume-section-body {
    float: right;
    height: 100%;
    display: block;
}

.resume-section h2 {
    float: left;
    min-width: 25rem;
    padding-bottom: 0;
    margin-bottom: 0;
}

.resume-section-body p {
    margin: 0;
}

.resume-section-item ul {
    padding-left: 1.25rem;
    padding-top: 0;
    margin-top: 0
}

.resume-section-item ul li {
    word-wrap: break-word
}

.resume-section-item h3 {
    margin-bottom: 0;
    color: var(--accent-color);
}

#grading-tool-header  {
    /* Add a little emphasis :) */
    font-size: 1.5rem;
}

.resume-section-item h4 {
    margin-top: 0;
    margin-bottom: 0
}

.resume-section-item p a {
    color: var(--accent-color);
    text-decoration: none;
}

.resume-button-link {
    text-decoration: none
}

.resume-button-link:hover {
    text-decoration: none;
    font-size: 1rem
}

.resume-button {
    text-align: left;
    max-width: 40rem;
    margin: 0 auto;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    margin-top: 3rem;
    margin-bottom: -10rem;
    -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .2);
    position: relative;
    z-index: 100
}

.resume-button-text {
    margin-left: -15rem;
    margin-top: 3rem
}

.resume-button:hover {
    cursor: pointer;
    border-color: var(--accent-color);
}

@media only screen and (max-width: 750px) {
    .resume-section .section-body {
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .resume-section h2 {
        min-width: 0;
    }
}

@media only screen and (max-width:750px) {
    .resume-button-text {
        margin-left: 0;
        margin-top: -2rem
    }
}

@media only screen and (max-width:750px) {
    .resume-button {
        text-align: center
    }
}