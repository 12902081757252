.info-text input:focus {
    outline: none;
}

.info-body input {
    margin: 0;
    background-color: var(--background-color);
    color: var(--text-color);
    border: none;
    width: 90%;
    padding: 0;
    font-size: 1rem;
    font-family: 'VT323', monospace;
    overflow-wrap: break-word;
    word-break: break-all;
}

.info-text p {
    overflow-wrap: break-word;
    word-break: break-all;
}

.info-text p a {
    font-weight: bold;
    color: var(--text-color);
}
