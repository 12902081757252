html {
  --border-color: rgba(0,0,0, 0.125);
  -webkit-transition: 2s; /* Safari */
  transition: opacity 2s;
  transition-timing-function: ease-in-out;
}

html[data-theme='dark'] {
  --background-color: #2E282A;
  --lighter-background-color: #484244;
  --accent-color: #3995e7;
  --text-color: #fff;
  -webkit-transition: 2s; /* Safari */
  transition: opacity 2s;
  transition-timing-function: ease-in-out;
}

html[data-theme='light'] {
  --background-color: #fff;
  --lighter-background-color: #fff;
  --accent-color: #278ce5;
  --text-color: #000;
  -webkit-transition: 2s; /* Safari */
  transition: opacity 2s;
  transition-timing-function: ease-in-out;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Arimo', sans-serif;
}

/* universal classes */
.no-border {
  border: none!important
}